<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-card-title class="title">
            Bill Generate
            <v-spacer></v-spacer>
            <transition
              v-if="generate"
              name="animate-css-transition"
              enter-active-class="animated fadeInRight"
              leave-active-class="animated fadeOutRight"
            >
              <add-button
                v-if="this.grade"
                @action="generateBill"
                :permission="'bill-generate-class-wise'"
                icon="play_for_work"
                >Generate
              </add-button>
              <!-- <v-btn outlined @click="generateBill" ma-0 small v-if="this.grade">Generate</v-btn> -->
            </transition>
            <transition
              v-if="is_journal_entry == 0 && print"
              name="animate-css-transition"
              enter-active-class="animated fadeInRight"
              leave-active-class="animated fadeOutRight"
            >
              <add-button
                @action="reverseBill"
                :permission="'bill-generate-rollback'"
                icon="refresh"
              >
                Rollback
              </add-button>
            </transition>
            <transition
              v-if="is_journal_entry == 2"
              name="animate-css-transition"
              enter-active-class="animated fadeInRight"
              leave-active-class="animated fadeOutRight"
            >
              <add-button
                @action="printBill"
                :permission="'bill-generate-class-wise'"
                icon="print"
              >
                Print
              </add-button>
            </transition>
            <transition
              v-if="is_bill_generate == 1 && is_journal_entry == 0 && print"
              name="animate-css-transition"
              enter-active-class="animated fadeInRight"
              leave-active-class="animated fadeOutRight"
            >
              <add-button
                @action="confirmSyncDialog = true"
                :permission="'bill-generate-sync-to-account'"
                icon="check_circle"
                >Sync to Account
              </add-button>
            </transition>
            <transition
              v-if="is_journal_entry == 1"
              name="animate-css-transition"
              enter-active-class="animated fadeInRight"
              leave-active-class="animated fadeOutRight"
            >
              <add-button
                :permission="true"
                icon="check_circle"
                :disabled="true"
                >Waiting for approval from Admin
              </add-button>
            </transition>
            <refresh-button
              v-show="form.items.data.length"
              @action="get()"
            ></refresh-button>
          </v-card-title>
          <v-card outlined>
            <v-card-title class="title pb-0">
              <v-flex xs3 sm4>
                <v-select
                  :disabled="gradeLoading"
                  :loading="gradeLoading"
                  :items="grades"
                  class="pa-0"
                  label="Grade"
                  v-model="grade"
                  outlined
                  dense
                />
              </v-flex>
              <v-flex xs3 sm4>
                <v-select
                  :disabled="!months.length"
                  :loading="monthLoading"
                  :items="months"
                  class="pa-0"
                  label="Month"
                  v-model="month"
                  outlined
                  dense
                />
              </v-flex>
            </v-card-title>
          </v-card>
          <div
            v-if="form.items.data.length"
            class="data-represent"
            style="margin-top: 20px"
          >
            <div>
              <span class="d-success"></span> &nbsp;
              <small> Paid</small>
            </div>
            <div>
              <span class="d-warning"></span> &nbsp;
              <small> Partially Paid</small>
            </div>

            <div>
              <span class="d-error"></span> &nbsp;
              <small> Unpaid</small>
            </div>
            <div>
              <span class="d-primary"></span> &nbsp;
              <small> Bill not generated</small>
            </div>
          </div>

          <v-data-table
            :headers="headers"
            :items="form.items.data"
            :expand="expand"
            item-key="name"
            :search="search"
            :loading="form.loading"
            :options.sync="pagination"
            :footer-props="footerProps"
            :server-items-length="form.items.meta.total"
          >
            <template v-slot:item="{ index, item }">
              <tr :class="'highlight'" @click="expanded = !expanded">
                <td class="text-xs-left">
                  <span v-if="item.bills[0]">
                    <span
                      class="d-successd"
                      v-if="
                        dueAmount(item.bills[0], index) === '0.00' ||
                          dueAmount(item.bills[0], index) === 0
                      "
                    ></span>
                    <span
                      class="d-errord"
                      v-else-if="paidAmount(item.bills[0], index) === 0"
                    ></span>
                    <span class="d-warningd" v-else></span>
                  </span>
                  <span v-else>
                    <span class="d-primaryd"></span>
                  </span>
                  {{ index + 1 }}
                </td>
                <td class="text-xs-left">
                  {{ item.enroll_code }}
                  <span v-if="item.bills.length">
                    <v-icon small v-if="item.bills[0].invoice_no" color="teal">
                      check_circle
                    </v-icon>
                  </span>
                </td>
                <td class="text-xs-left">
                  {{
                    item.bills.length > 0
                      ? item.bills[0].invoice_no
                        ? item.bills[0].invoice_no.split("-").pop()
                        : "N/A"
                      : "N/A"
                  }}
                </td>
                <td class="text-xs-left">{{ item.roll }}</td>
                <td class="text-xs-left">{{ item.name }}</td>
                <td class="text-xs-left">{{ item.section }}</td>
                <td class="text-xs-left">
                  {{
                    item.bills.length > 0 ? dueAmount(item.bills[0], index) : ""
                  }}
                </td>
                <td class="text-xs-right">
                  <v-btn
                    v-if="
                      is_journal_entry == 2 &&
                        $auth.can('bill-generate-student-wise')
                    "
                    small
                    icon
                    color="primary"
                    class="pa-0 ma-1"
                    @click="singlePrint(item.enroll_code)"
                    :disabled="
                      item.bills.length > 0
                        ? item.bills[0].invoice_no
                          ? false
                          : true
                        : true
                    "
                  >
                    <v-icon dark>print</v-icon>
                  </v-btn>
                  <!-- <v-icon v-if="print" color="success" outlined small
                                           @click="singlePrint(props.item.enroll_code)" :disabled="(props.item.bills.length > 0) ? (props.item.bills[0].invoice_no ?
                                    false : true) : true ">print
                                </v-icon> -->
                </td>
              </tr>
            </template>
            <template v-slot:expand="props">
              <v-card v-if="props.item.bills.length > 0" style="width: 100%;">
                <table width="100%" class="inner-table">
                  <thead>
                    <tr>
                      <th class="text-xs-left"><strong>Fee Head</strong></th>
                      <th class="text-xs-left">
                        <strong>Fee Head Amount </strong>
                      </th>
                      <th class="text-xs-left"><strong>Discount </strong></th>
                      <th class="text-xs-left">
                        <strong>Scholarship </strong>
                      </th>
                      <th class="text-xs-left"><strong>Paid Amount</strong></th>
                      <th class="text-xs-left"><strong>Due Amount</strong></th>
                      <th class="text-xs-left"><strong>Action</strong></th>
                    </tr>
                    <tr
                      v-for="(x, i) in props.item.bills[0].details"
                      :key="i"
                      style="height: 0px"
                    >
                      <td class="text-xs-left">{{ x.fee_head }}</td>
                      <td class="text-xs-left">{{ x.fee_head_amount }}</td>
                      <td class="text-xs-left">{{ x.discount }}%</td>
                      <td class="text-xs-left">{{ x.scholarship }}%</td>
                      <td class="text-xs-left">{{ x.paid_amount }}</td>
                      <td class="text-xs-left">{{ x.due_amount }}</td>
                      <td v-if="x.extra == 1 && is_journal_entry == 0">
                        <delete-button
                          permission="fee-delete"
                          @agree="extraDelete(x)"
                        />
                      </td>
                      <td v-else>-</td>
                    </tr>
                  </thead>
                  <tfoot v-if="props.item.bills.length > 0">
                    <!--                                    <tr v-if="dueAmount(props.item.bills[0],props.index) > 0">-->
                    <tr v-if="print">
                      <td :colspan="headers.length" style="text-align: right;">
                        <add-button
                          v-if="is_journal_entry == 0"
                          permission="bill-generate-class-wise"
                          @action="addExtra(props.item)"
                          >Add Extra
                        </add-button>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </v-card>
              <v-card v-else style="margin-left: 30px">
                No Bill generated
              </v-card>
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="warning"
            >
              Your search for "{{ search }}" found no results.
            </v-alert>
          </v-data-table>
        </v-card>
      </v-flex>
      <v-dialog v-model="confirmDialog" persistent max-width="290">
        <v-card>
          <v-card-title class="title pa-3 warning white--text">
            <v-icon class="mr-2 white--text">warning</v-icon>
            Please, wait!
          </v-card-title>
          <v-card-text class="ma-0">{{ warningMessage }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" outlined small @click="confirmDialog = false"
              >No</v-btn
            >
            <v-btn
              v-if="generate"
              color="warning"
              outlined
              small
              @click="generateBill"
              >Yes</v-btn
            >
            <v-btn
              v-if="print"
              color="warning"
              outlined
              small
              @click="reverseBill"
              >Yes</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="confirmSyncDialog" persistent max-width="290">
        <v-card>
          <v-card-title class="title pa-3 warning white--text">
            <v-icon class="mr-2 white--text">warning</v-icon>
            Please, wait!
          </v-card-title>
          <v-card-text class="ma-0"
            >Are you sure ,This bills has been completed?</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="success"
              outlined
              small
              @click="confirmSyncDialog = false"
              >No</v-btn
            >
            <v-btn color="warning" outlined small @click="syncToAccount"
              >Yes</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="reasonDialog" persistent max-width="290">
        <v-card>
          <v-card-title class="title pa-3">
            <v-textarea
              outlined
              name="input-7-4"
              label="Reason to Rollback"
              v-model="reason"
            ></v-textarea>
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" outlined small @click="reasonDialog = false"
              >Cancel</v-btn
            >
            <v-btn
              :disabled="!reason"
              color="warning"
              outlined
              small
              @click="rollBack"
              >Rollback</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="addDialog" persistent max-width="290">
        <v-card>
          <v-card-title class="title pa-3">
            <v-select
              :disabled="!fees.length"
              :items="fees"
              class="pa-0"
              label="Fee Head"
              v-model="fee_head_id"
            />
            <v-text-field
              :disabled="
                feeOfferDiscountPer > 0 ||
                  feeOfferScholarPer > 0 ||
                  !fee_head_id
              "
              autocomplete="off"
              :label="labelFeeHead"
              required
              class="pa-0 pt-3"
              v-model="total"
              :persistent-hint="true"
              :hint="
                feeOfferDiscountPer > 0
                  ? 'Fee Offer Discount ' + feeOfferDiscountPer + '%'
                  : '' + feeOfferScholarPer > 0
                  ? 'Fee Offer Scholar ' + feeOfferScholarPer + '%'
                  : ''
              "
            />
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" outlined small @click="addDialog = false"
              >Cancel</v-btn
            >
            <v-btn
              :disabled="!fee_head_id"
              color="warning"
              outlined
              small
              @click="submit"
              >Add</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="alertDialog" persistent max-width="460px">
        <v-card>
          <v-card-title class="title pa-3 error white--text">
            <v-icon class="mr-2 white--text">warning</v-icon>
            Please, wait!
          </v-card-title>
          <v-card-text class="ma-0">
            Previous Due Journal Entry process is still pending.
            <br />
            If you continue billing, Students' Previous Due will not included in
            this billing.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" outlined small @click="alertDialog = false"
              >Ok</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";
import Mixins from "@/library/Mixins";
import { getPropertyFromArrayObject } from "@/library/helpers";

export default {
  mixins: [Mixins],
  data: () => ({
    form: new Form(
      {
        bill_month: "",
        grade_id: "",
        billing: "",
      },
      "/api/invoice"
    ),
    search: null,
    warningMessage: "",
    headers: [
      { text: "#", align: "left", value: "id", sortable: false },
      {
        text: "Enroll Code",
        align: "left",
        value: "enroll_code",
        sortable: false,
      },
      {
        text: "Invoice No",
        align: "left",
        value: "invoice_no",
        sortable: false,
      },
      { text: "Roll No", align: "left", value: "roll", sortable: true },
      { text: "Name", align: "left", value: "name", sortable: false },
      { text: "Section", align: "left", value: "section", sortable: true },
      {
        text: "Due Amount",
        align: "left",
        value: "fee_head_amount",
        sortable: false,
      },
      { text: "Action", align: "right", value: "action", sortable: false },
    ],
    grades: [],
    grade: "",
    sections: [],
    section: "",
    months: [],
    month: "",
    generate: false,
    expand: false,
    print: false,
    billingSetting: {},
    confirmDialog: false,
    reasonDialog: false,
    addDialog: false,
    reason: "",
    filterData: [],
    fee_head_amount: "",
    labelFeeHead: "Fee Head Amount",

    fee_heads: [],
    fee_head: "",
    fees: [],
    fee_head_id: "",
    feeAmounts: [],
    gradeLoading: false,
    monthLoading: false,
    itemData: [],
    feeOfferDiscountPer: 0,
    feeOfferScholarPer: 0,
    total: "",
    counter: 0,
    is_bill_generate: 0,
    is_journal_entry: 0,
    id: "",
    confirmSyncDialog: false,
    alertDialog: false,
  }),

  computed: {
    ...mapState(["batch"]),
  },

  mounted() {
    this.getGrades();
    this.$rest.get("/api/previous-due-pending").then((res) => {
      if (res.data !== 0) this.alertDialog = true;
      console.log(res.data);
    });
  },

  watch: {
    batch: function(value) {
      // this.get();
    },
    month: function(value) {
      this.generate = false;
      this.print = false;
      if (this.grade && this.month) {
        this.getBilling();
        this.get();
      }
    },
    pagination: function() {
      this.get();
    },
    grade: function(value) {
      this.form.items.data = [];
      this.month = "";
      if (this.grade) {
        this.getMonth();
      }
    },
    fee_head_id: function(value) {
      if (value) {
        this.$rest
          .get(
            "/api/student-fee-offer?" +
              "enrollId=" +
              this.itemData.enroll_id +
              "&feeHeadId=" +
              value
          )
          .then((result) => {
            // console.log(result);
            if (result.data) {
              this.feeOfferDiscountPer = result.data.discount_percentage;
              this.feeOfferScholarPer = result.data.scholar_percentage;
            } else {
              this.feeOfferDiscountPer = 0;
              this.feeOfferScholarPer = 0;
            }

            if (this.feeOfferScholarPer > 0)
              this.total -= (this.feeOfferScholarPer / 100) * this.total;
            if (this.feeOfferDiscountPer > 0)
              this.total -= (this.feeOfferDiscountPer / 100) * this.total;

            if (
              (this.feeOfferDiscountPer > 0 || this.feeOfferScholarPer > 0) &&
              this.fee_head_amount > 0
            ) {
              this.labelFeeHead =
                this.labelFeeHead + " Rs." + this.fee_head_amount;
            } else {
              this.labelFeeHead = "Fee Head Amount";
            }
          });

        this.feeAmounts = this.fees.find((res) => res.value == value);
        this.fee_head_amount = this.feeAmounts.amount;
        this.total = this.feeAmounts ? this.feeAmounts.amount : "";
        this.fee_head = this.feeAmounts ? this.feeAmounts.text : "";
      }
    },
  },

  methods: {
    get(params) {
      if (this.grade) {
        let extraParams = "&billMonth=" + this.month + "&gradeId=" + this.grade;
        let query = [null, undefined].includes(params)
          ? this.queryString(extraParams)
          : params;
        this.form
          .get(null, query)
          .then(({ data }) => {
            if (data.stat) {
              this.is_bill_generate = data.stat.is_bill_generate;
              this.is_journal_entry = data.stat.is_journal_entry;
              this.id = data.stat.id;
            } else {
              this.is_bill_generate = 0;
              this.is_journal_entry = 0;
              this.id = "";
            }

            let i = 0;
            let j = 0;
            for (i = 0; i < data.data.length; i++) {
              if (data.data[i].bills.length === 0) {
                j++;
              }
            }
            if (j === data.data.length) {
              this.generate = true;
              this.print = false;
            } else {
              this.print = true;
              this.generate = false;
            }
          })
          .catch((e) => {
            this.$events.fire("notification", {
              message: e.response.data.message,
              status: "error",
            });
          });
      }
    },

    save() {
      this.form.batchId = this.batch.id;
      this.form.grade_id = this.grade;
      this.form.bill_month = this.month;
      this.form.store();
    },

    getGrades() {
      this.gradeLoading = true;
      this.$rest
        .get("/api/grades?rowsPerPage=25&descending=false&slim=true")
        .then(({ data }) => {
          this.grades = data.data.map((item) => {
            return { value: item.id, text: item.name };
          });
        })
        .finally(() => {
          this.gradeLoading = false;
        });
    },
    getFeeHead() {
      this.$rest.get("/api/fee-head").then(({ data }) => {
        this.fee_heads = data.data.map((item) => {
          return { value: item.id, text: item.title };
        });
      });
    },
    generateBill() {
      if (!this.confirmDialog) {
        this.warningMessage = "Are you sure you want to generate bill?";
        this.confirmDialog = true;
      } else {
        this.form.batch_id = this.batch.id;
        this.form.grade_id = this.grade;
        this.form.bill_month = this.month;
        this.form.billing = this.billing;
        this.form
          .store()
          .then(({ data }) => {
            this.confirmDialog = false;
            this.generate = false;
            this.print = true;
          })
          .catch((e) => {
            this.confirmDialog = false;
            this.$events.fire("notification", {
              message: data.message,
              status: "error",
            });
          });
      }
    },
    reverseBill() {
      this.reason = "";
      if (!this.confirmDialog) {
        this.warningMessage =
          "Are you sure you want to Rollback generated bill?";
        this.confirmDialog = true;
      } else {
        this.confirmDialog = false;
        this.reasonDialog = true;
      }
    },
    printBill() {
      let url =
        "?billing=" +
        this.billing +
        "&billMonth=" +
        this.month +
        "&gradeId=" +
        this.grade;
      this.$rest.get("/api/download/bill/bulk").then(({ data }) => {
        window.open(data.data.download_url + url);
      });
    },
    singlePrint(enCode) {
      let url =
        "?billing=" +
        this.billing +
        "&billMonth=" +
        this.month +
        "&enrollCode=" +
        enCode;
      this.$rest.get("/api/download/bill/single").then(({ data }) => {
        window.open(data.data.download_url + url);
      });
    },
    rollBack() {
      let formData = new FormData();
      formData.append("grade_id", this.grade);
      formData.append("bill_month", this.month);
      formData.append("billing", this.billing);
      formData.append("reason", this.reason);
      this.$rest
        .post("/api/invoice/rollback-bulk", formData)
        .then(({ data }) => {
          this.reasonDialog = false;
          this.print = false;
          this.generate = false;
          this.grade = "";
          this.form.items.data = [];
          this.$events.fire("notification", {
            message: data.message,
            status: "success",
          });
        })
        .catch((e) => {
          this.$events.fire("notification", {
            message: e.response.data.message,
            status: "error",
          });
        });
    },
    getMonth() {
      if (this.grade) {
        this.monthLoading = true;
        this.$rest
          .get("/api/invoice/billNow?gradeId=" + this.grade)
          .then(({ data }) => {
            this.months = data.map((item) => {
              return {
                value: item.generate_month,
                text: item.bill_month_name,
                billing: item.billing,
                id: item.id,
              };
            });
          })
          .finally(() => {
            this.monthLoading = false;
          });
      }
    },
    getBilling() {
      let $this = this;
      this.filterData = this.months.filter(function(data) {
        if (data.value == $this.month) {
          return data;
        }
      });
      this.billing = this.filterData[0].billing;
    },
    findBillingMonth(month) {
      return this.months.find((res) => res.value === month);
    },
    addExtra(item) {
      this.itemData = item;
      this.fee_head_amount = "";
      this.fee_head_id = "";
      this.total = "";

      this.labelFeeHead = "Fee Head Amount";
      this.feeOfferDiscountPer = 0;
      this.feeOfferScholarPer = 0;

      this.addDialog = true;
      this.$rest
        .get("/api/fee?gradeId=" + this.grade + "&batchId=" + this.batch.id)
        .then(({ data }) => {
          let existingDetails = item.bills[0].details;
          let existingDetailsName = getPropertyFromArrayObject(
            "fee_head",
            existingDetails
          );

          this.fees = [];
          data.data.map((res) => {
            // console.log(res);
            if (
              !existingDetailsName.includes(res.fee_head.title) &&
              res.generate_bill === 0
            )
              this.fees.push({
                value: res.fee_head_id,
                text: res.fee_head.title,
                amount: res.amount,
              });
          });
        });
    },
    submit() {
      this.addDialog = false;
      this.$rest
        .post("/api/invoice/add-item", {
          invoice_no: this.itemData.bills[0].invoice_no,
          fee_head: this.fee_head,
          fee_head_amount: this.fee_head_amount,
          fee_head_id: this.fee_head_id,
          total: this.total,
          enroll_id: this.itemData.enroll_id,
          discount: this.feeOfferDiscountPer,
          scholar: this.feeOfferScholarPer,
          billing_month_id: this.findBillingMonth(this.month).id,
          grade_id: this.grade,
        })
        .then(({ data }) => {
          this.get();
          this.$events.fire("notification", {
            message: e.response.data.message,
            status: "success",
          });
        })
        .catch((e) => {
          this.$events.fire("notification", {
            message: e.response.data.message,
            status: "error",
          });
        });
    },
    extraDelete(item) {
      this.$rest
        .delete("/api/invoice/delete-item/" + item.id)
        .then(({ data }) => {
          this.get();
          this.$events.fire("notification", {
            message: data.message,
            status: "success",
          });
        })
        .catch((e) => {
          this.$events.fire("notification", {
            message: e.response.data.message,
            status: "error",
          });
        });
    },
    dueAmount(item, index) {
      let amount = 0;
      item.details.map((res) => {
        amount += parseFloat(res.due_amount);
      });

      let tableRows = document.getElementsByClassName("highlight");

      if (tableRows.length) {
        if (amount <= 0) {
          // tableRows.item(index).style.background = '#a9fdab';
        } else if (amount < item.amount) {
          // tableRows.item(index).style.background = '#fbcba7';
        } else {
          // tableRows.item(index).style.background = '';
        }
      }

      // if(tableRows)
      // tableRows.style.background='red';
      // if(item.amount-amount > 0){

      // }

      // console.log(tableRows);
      if (amount < 0) amount = 0;
      return amount.toFixed(2);
    },
    paidAmount(item, index) {
      let paid_amount = 0;
      item.details.map((res) => {
        paid_amount += parseFloat(res.paid_amount);
      });
      return paid_amount;

      //     if(paid_amount < due_amount ) return true
      //     return false;
    },
    syncToAccount() {
      this.$rest
        .post("/api/billing-log-sync", { id: this.id })
        .then(({ data }) => {
          this.get();
          this.confirmSyncDialog = false;
          this.$events.fire("notification", {
            message: data.message,
            status: "success",
          });
        })
        .catch((e) => {
          this.confirmSyncDialog = false;
          this.$events.fire("notification", {
            message: data.message,
            status: "error",
          });
        });
    },
  },
};
</script>
<style lang="scss">
.highlight {
  td {
    font-weight: bold !important;
  }
}

tr.highlight {
  cursor: pointer;
}

.d-successd {
  background: #4caf50;
  position: absolute;
  height: 32px;
  margin-top: -8px;
  margin-left: -21px;
  width: 5px;
}

.d-errord {
  background: #ff5252;
  position: absolute;
  height: 32px;
  margin-top: -8px;
  margin-left: -21px;
  width: 5px;
}

.d-warningd {
  background: #ff9800;
  position: absolute;
  height: 32px;
  margin-top: -8px;
  margin-left: -21px;
  width: 5px;
}

.d-primaryd {
  background: #1976d2;
  position: absolute;
  height: 32px;
  margin-top: -8px;
  margin-left: -21px;
  width: 5px;
}
</style>
